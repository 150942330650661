@charset "UTF-8";

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

/*********************** Reset.css - сброс стилей *****************/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
img,
strong,
center,
ol,
ul,
li,
form,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
canvas,
footer,
header,
menu,
nav,
section,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255, 0) !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}
:focus {
  outline: none;
}
a,
a:hover {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}
a:hover {
  // color: var(--main_color);
}
li {
  list-style-type: none;
}
input {
  border: none;
}
:root {
  --header_bg_color: #ffffff;
  --header_text_color: #444f60;
  --main_color: #eda514;
  --border_color: #000000;
  --section_bg_color: #ebebeb;
  --section_text_color: #000000;
  --footer_bg_color: #333333;

  --menu_font_size: 14px;
  --text_font_size: 24px;
  --h1_font_size: 96px;
  --h2_font_size: 32px;
  --h3_font_size: 24px;
  --h4_font_size: 20px;
  --h5_font_size: 16px;
  --h6_font_size: 12px;
}
* {
  box-sizing: border-box;
}

/************************************************************************/

html {
  scroll-behavior: smooth;
}
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  // font-size: 24px;
  // font-weight: normal;
  // color: var(--main_text_color);
}
section {
  position: relative;
  margin: 50px 0;
}
section:first-child {
  margin-top: 0;
  padding-top: 0;
}
.antifooter {
  flex-grow: 1;
}
footer {
  flex-shrink: 0;
}
img {
  max-width: 100%;
  object-fit: cover;
}
#back-top {
  display: none;
  cursor: pointer;
}
#back-top {
  position: fixed;
  right: 2%;
  bottom: 50px;
  z-index: 99;
  font-size: 32px;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.row {
  display: flex;
}
/* .col-5 {
    width: 50%;
} */
.bg_wrap {
  position: relative;
}
.bg_wrap:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
}
p {
  font-size: var(--h5_font_size);
}
.prime__color {
  color: var(--main_color);
}
.bold {
  font-weight: 600;
}

/*------------------- Mobile menu ------------------*/
.hamburger {
  display: none;
  cursor: pointer;
  z-index: 99;
  padding: 20px;
}
.bar1,
.bar2,
.bar3 {
  height: 4px;
  width: 35px;
  /* background: linear-gradient(122.85deg, var(--blue) 0%, var(--white) 99.25%); */
  background-color: var(--main_color);
  transition: 0.4s;
  margin: 6px 0;
  border-radius: 10px;
}
.hamburger.active .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 6px);
  transform: rotate(-45deg) translate(-8px, 6px);
}
.hamburger.active .bar2 {
  opacity: 0;
}
.hamburger.active .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-8px, -6px);
}

/*------------------------------ Scroll ----------------------------------*/
::-webkit-scrollbar {
  width: 8px;
  /* ширина для вертикального скролла */
  background-color: #fff;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  // background: linear-gradient(360deg, var(--main_color) 25%, #ffffff 85%);
  border-radius: 10px;
  /* box-shadow: inset 1px 1px 1px #fff; */
}

/*------------------------------ Modal ----------------------------------*/
.modal__background {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  backdrop-filter: blur(5px);
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  /* transform: scale(0.9); */
  transition: all 300ms;
}
.modal__background.active {
  visibility: visible;
  opacity: 1;
  /* transform: translateX(0); */
}
.modal__body {
  max-width: 700px;
  background-color: #ededed;
  padding: 70px 50px;
  /* padding: 70px 130px; */
  color: var(--main_text_color);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  transition: all 300ms;
}
.modal__background.active .modal__body {
  transform: translate(-50%, -50%) scale(1);
}
.modal__close {
  position: absolute;
  right: 1%;
  top: 1%;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  z-index: 110;
  line-height: 1;
  color: var(--main_color);
}
/*-------------------------------------------------------------------------*/

.container {
  max-width: 1320px;
}

@media (max-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media (max-width: 992px) {
  .container {
    max-width: 720px;
  }

  .mobile_menu {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
}
