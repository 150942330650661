@media (min-width: 1600px) {
  .teachers .hero-body .is-header-caption {
    margin-bottom: 5%;
  }
}
@media (max-width: 1600px) {
  .hero.parallax {
    background-position: 0% 25% !important;
  }
}
@media (max-width: 1200px) {
  .hero.parallax {
    background-position: 0% 15% !important;
  }
}
@media (min-width: 1024px) {
  .calendar {
    .card-text {
      bottom: -70%;
    }
  }
}
@media (max-width: 992px) {
  header {
    .navbar > .container {
      display: block;
    }
    .navbar-menu {
      flex-grow: 1;
    }
    .navbar-wrapper {
      // height: 84px;
    }
  }

  .responsive-table {
    min-width: auto;
  }
}
@media (max-width: 568px) {
  header {
    .navbar {
      & > .container {
        padding: 0;
      }
      .navbar-brand {
        z-index: 99;
        position: relative;
        background-color: #fff;
      }
      &-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: calc(100vh - 84px);
        width: 100%;
        position: fixed;
        top: -100%;
        transition: 0.6s;
        z-index: 9;
        padding-top: 40px;
        &.is-active {
          top: 84px;
        }
      }
      &-start {
        width: 100%;
        margin-bottom: 40px;
        .navbar-item {
          margin-bottom: 5px;
        }
      }
      .navbar__social,
      .navbar__language {
        display: flex;
        justify-content: center;
        width: 100%;
        & {
          .navbar-item {
            width: auto;
          }
        }
      }
      &-wrapper .navbar-item {
        font-size: 18px;
      }
    }
    .navbar-wrapper.is-transparent .navbar-item.is-slide:after,
    .navbar-wrapper.is-transparent .navbar-link.is-slide:after {
      display: none;
    }
  }
  footer.footer-dark-left .footer-nav-right {
    display: none;
  }
  .section {
    padding: 3rem 0;
  }
  .section.is-skewed-sm {
    padding-top: 40px;
  }
  .section .content-wrapper {
    // padding: 0;
  }
  .centered-title .subheading {
    padding: 20px 0px;
  }
  .hero {
    &.is-fullheight .hero-body {
      align-items: start;
    }
    &.is-fullheight {
      min-height: auto;
    }
    &-body .title.main-title {
      font-size: 32px;
    }
    & .image {
      // display: none;
    }
  }
  .mobile__direction {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }
  .download {
    &__content {
      gap: 20px;
      margin-top: 0;
    }
  }
  .section-title-wrapper .section-title-landing {
    font-size: 32px;
  }
  .home .section-title-wrapper .section-title-landing,
  .centered-title h2,
  .title.is-2,
  .section .title.quick-feature,
  .title.is-3 {
    font-size: 24px;
  }
  .centered-title .subheading,
  .body-text,
  .subtitle.is-5 {
    font-size: 16px;
  }
  .pt-40 {
    padding-top: 20px;
  }
  .pb-40 {
    padding-bottom: 20px;
  }
  .pt-60 {
    padding-top: 30px;
  }
  .pb-60 {
    padding-bottom: 30px;
  }
  .pt-80 {
    padding-top: 40px;
  }
  .pb-80 {
    padding-bottom: 40px;
  }
  .vertical-testimonials {
    & .vtestimonial-item {
      margin: 0 20px;
    }
    &.slick-slider .slick-list {
      margin-top: -50px;
    }
  }
  .system,
  .social {
    img {
      // display: none;
    }
  }
  .navigation-tabs .navtab-content {
    padding: 0;
  }
  .jobalar__list img {
    display: none;
  }
  .gallery-block__wrap {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 150px;
  }
  .faq {
    &-wrapper {
      padding: 0;
    }
  }
  .map {
    .columns {
      display: flex;
      flex-wrap: wrap;
      .shadow-icon-box {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
      }
      .shadow-icon-box span {
        height: 50px;
        width: 50px;
      }
    }
    & iframe {
      height: 400px;
    }
  }
  .teachers {
    .hero-body .is-header-caption {
      width: auto;
    }
    .hero.parallax {
      background-position: center !important;
    }
    .teachers-slider {
      .people-carousel {
        .people-carousel-item {
          & .image-column {
            width: 100%;
            margin-bottom: 20px;
            display: block;
            img {
              width: 100%;
            }
          }
          & .item-wrapper {
            flex-wrap: wrap;
            padding-bottom: 0;
          }
        }
        & .mobile-spacer {
          padding-bottom: 0;
        }
        .slick-dots {
          display: none !important;
        }
      }
    }
    &-boss {
      &-block {
        .columns .column {
          width: 100%;
        }
      }
    }
  }
}
