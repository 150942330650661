@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import "./variables.scss";

.color__prime {
  color: $primary;
}
a {
  color: inherit;
}
input[type=radio], input[type=checkbox] {
  accent-color: #b68a59;
}
h1,
h2,
h3,
h4,
span,
p {
  white-space: pre-line;
}
.backtotop {
  position: fixed;
  right: 0;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  margin: 0 25px 0 0;
  z-index: 9999;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  &.visible {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
    a {
      outline: none;
      text-decoration: none;
      border: 0 none;
      display: block;
      width: 46px;
      height: 46px;
      background-color: #66676b;
      opacity: 1;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      border-radius: 50%;
      text-align: center;
      font-size: 26px;
      &:after {
        outline: none;
        content: "";
        font-family: "simple-line-icons";
        font-weight: 900;
        font-size: 0.9rem;
        position: relative;
        display: block;
        top: 50%;
        -webkit-transform: translateY(-55%);
        transform: translateY(-55%);
        color: #fff;
      }
    }
  }
}
// header
header {
  min-height: 65px;
  .navbar > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-menu {
    flex-grow: 0;
  }
  .navbar-wrapper {
    height: max-content;
    & .navbar-brand {
      height: max-content;
    }
    & .navbar-brand img {
      height: 70px;
      max-height: max-content;
    }
    & .navbar-item {
      font-size: 16px;
    }
    & button.navbar-item {
      border: none;
      background: none;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: auto;
        &::after {
          display: none;
        }
      }
    }
  }
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    color: $primary;
  }
}
// BreadCrumps
.breadcrumb {
  &.styled {
    margin-bottom: 40px;
  }
  a {
    display: inline-block;
    &:first-letter {
      text-transform: uppercase;
    }
  }
}
// App
.single-image-carousel .image-wrapper img {
  max-height: 400px;
  object-fit: contain;
}
.slick-dots li button:before {
  font-size: 24px;
}
.section {
  .title.section-title,
  .section-title-landing {
    font-weight: 600;
  }
}
.title:not(.is-spaced) + .subtitle {
  margin-top: 0;
  margin-bottom: 40px;
}
.jobalar {
  i[class^="im im-icon"] {
    font-size: 3.4rem;
    font-weight: normal;
    color: #eda514;
  }
  .columns {
    flex-wrap: wrap;
  }
  .feature-card.card-md {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
}
.responsive-table {
  min-width: 600px;
  margin-bottom: 10px;
  & td:first-child:before {
    text-align: center !important;
    width: 100%;
  }
  & td:before {
    content: attr(data-th) "";
  }
}
.hero.parallax {
  background-position: 0% 38% !important;
  background-repeat: no-repeat;
  background-attachment: scroll !important;
  min-height: 450px;
}
.hero .parallax-overlay {
  opacity: 0.4 !important;
}
.hero-body .title.main-title {
  text-transform: uppercase;
}
.timetable {
  &_wrap {
    flex-wrap: wrap;
  }
  .image-carousel {
    max-width: 1200px;
  }
}
// Home page
.home {
  .title.main-title {
    font-size: 3rem;
  }
  .hero.is-app-grey {
    background-color: #fff;
  }
  .section-title-wrapper .section-title-landing {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .centered-title .subheading {
    max-width: 100%;
    color: #444f60;
  }
  .hero {
    .image img {
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px #bdbdbd;
    }
  }
}
.media__links {
  display: flex;
  align-items: center;
  & > a:first-child {
    margin-right: 30px;
  }
}
// Politics page
.download {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px 20px;
    margin-top: 30px;
  }
  &__item {
    display: inline-flex;
    align-items: center;
    width: 250px;
    border-radius: 25px;
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    transition: 0.3s;
    &:hover {
      box-shadow: 0 0px 10px 0px $primary;
      scale: 1.1;
    }
  }
  &__icon {
    margin-right: 20px;
    font-size: 32px;
  }
  &__name {
    color: #444f60;
    font-size: 14px;
  }
}
// About
.calendar {
  .img-container img {
    object-position: 0 -60px;
  }
}
.about {
  .section {
    &__students {
      img {
        max-height: 350px;
        width: 100%;
      }
    }
    & .calendar__block .title.section-title {
      font-size: 1.5rem;
    }
  }
  .mission__image {
    text-align: center;
    img {
      max-height: 500px;
    }
  }
}
// Vacancies
.vacancies {
  .is-medium.input {
    font-size: 16px;
  }
}
// Mugs
.mugs {
}
.animated-tabs {
  .section .feature-headline {
    margin-bottom: 10px !important;
  }
  .image img {
    max-height: 500px;
  }
}
.about__child {
  h2 {
    text-transform: uppercase;
  }
  // & > .section:first-child {
  //   padding-bottom: 0;
  // }
  // & > .section:nth-child(2) {
  //   padding-top: 0;
  // }
  // .section .section-feature-description {
  //   font-size: 16px;
  // }
}
.teachers {
  .hero.parallax {
    background-position: 0% 20% !important;
    min-height: calc(100vh - 85px);
  }
  .hero-body {
    display: flex;
    align-items: flex-end;
    & .is-header-caption {
      width: max-content;
      margin-bottom: 10%;
    }
  }
  &-boss {
    &.section.is-medium {
      padding-bottom: 0;
    }
    &-block {
      .columns .column {
        width: 20%;
      }
    }
  }
  .image-grid figure figcaption {
    top: 150px;
    padding-bottom: 0;
  }
  figure.team-slanted:hover h2 {
    transform: translate(0px, -70px);
  }
  figure.team-slanted:hover .member-position {
    transform: translate(0px, -60px);
  }
  .teachers-slider {
    .testimonial-title {
      span {
        font-size: 14px;
        line-height: normal;
        display: block;
      }
      i {
        top: 5px;
        right: 10px;
      }
    }
    .testimonial-content p {
      font-size: 14px;
      padding-bottom: 5px;
    }
    .people-carousel {
      .slick-dots {
        right: 40%;
        display: flex;
        & li {
          margin: 0;
        }
      }
      // /* Скрытие всех маркеров по умолчанию */
      .slick-dots li {
        display: none;
      }
      // /* Показ только активных маркеров и двух маркеров, следующих за активным */
      .slick-dots li.slick-active,
      .slick-dots li.slick-active + li,
      .slick-dots li.slick-active + li + li,
      .slick-dots li.slick-active + li + li + li,
      .slick-dots li.slick-active + li + li + li + li {
        display: block;
      }
      // /* Показ последних трех маркеров, когда класс slick-active не применяется к любому li перед ними */
      // .slick-dots li:nth-last-child(1),
      // .slick-dots li:nth-last-child(2),
      // .slick-dots li:nth-last-child(3) {
      //   display: block;
      // }
      // /* Скрытие последних трех маркеров, если slick-active существует перед ними */
      // .slick-dots li.slick-active ~ li:nth-last-child(1),
      // .slick-dots li.slick-active ~ li:nth-last-child(2),
      // .slick-dots li.slick-active ~ li:nth-last-child(3) {
      //   display: none;
      // }
      // /* Специфические условия для всегда видимых последних трех маркеров */
      // .slick-dots li.slick-active + li + li:nth-last-child(3),
      // .slick-dots li.slick-active + li + li:nth-last-child(2),
      // .slick-dots li.slick-active + li + li:nth-last-child(1),
      // .slick-dots li.slick-active + li:nth-last-child(3),
      // .slick-dots li.slick-active + li:nth-last-child(2),
      // .slick-dots li.slick-active + li:nth-last-child(1) {
      //   display: block;
      // }
      .people-carousel-item {
        & .carousel-img img {
          height: 500px;
          width: 350px;
        }
        & .item-wrapper {
          padding-bottom: 60px;
        }
        & .content-column {
          width: 60%;
        }
        & .image-column {
          width: 40%;
        }
      }
    }
  }
}
// Gallery
.gallery-block {
  &__wrap {
    // display: grid;
    // grid-gap: 10px;
    // grid-template-columns: 1.52fr 1fr 1fr 1.525fr;
    // grid-template-areas:
    //   "A  B  C  E"
    //   "A  D  D  E";

    // display: flex;
    // flex-wrap: wrap;
    // margin: -10px -10px;

    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
  }
  &__item {
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
    overflow: hidden;

    &:nth-child(6n + 4),
    &:nth-child(6n + 1) {
      grid-column: auto / span 2;
      grid-row: auto / span 2;
    }
    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 400ms ease-out;
      &:hover {
        transform: scale(1.15);
      }
    }
  }
  // contacts
  .map iframe {
    height: 600px;
  }
}
